<template>
    <div class="cert-group">
      <el-row :gutter="20">
        <el-col :span="24">
            <el-tabs type="border-card">
              <el-tab-pane :label="$t('data.approval.waitingApprove')">
                <el-table
                  :data="tableData"
                  style="width: 100%">
                  <el-table-column
                  :label="$t('data.approval.table.photo')"
                  width="80">
                    <template #default="scope">
                      <img :src="scope.row.member.avatar" alt="" class="table-user-photo">
                    </template>
                  </el-table-column>
                  <el-table-column
                  prop="member.name"
                  :label="$t('data.approval.table.applicantName')"
                  width="200">
                  </el-table-column>
                  <el-table-column
                  prop="cert.number"
                  :label="$t('data.approval.table.eCertNumber')">
                  </el-table-column>
                  <el-table-column
                  prop="cert.issue_date"
                  :label="$t('data.approval.table.issueDate')">
                  </el-table-column>
                  <el-table-column
                  prop="branch.name"
                  :label="$t('data.approval.table.campusBranch')">
                  </el-table-column>
                  <el-table-column
                  prop="created_at"
                  :label="$t('data.approval.table.requestedTime')">
                  </el-table-column>
                  <el-table-column
                  :label="$t('data.public.action')">
                      <template #default="scope">
                          <el-select v-model="scope.row.status" placeholder="Action" @change="auditChanged(scope.row)">
                              <el-option :label="$t('data.approvalStatus.pending')" :value="1" :disabled="true"></el-option>
                              <el-option :label="$t('data.approvalStatus.approved')" :value="2"></el-option>
                              <el-option :label="$t('data.approvalStatus.disapproved')" :value="3" :disabled="scope.row.status === 2 ? true : false"></el-option>
                          </el-select>
                      </template>
                  </el-table-column>
                </el-table>
                <table-pagination :page="page" @updatePage="updatePage"></table-pagination>
              </el-tab-pane>
            </el-tabs>
        </el-col>
        <el-col :span="24">
            <div class="mt-30 mb-30" align="center">
                <el-button type="primary">{{$t('data.public.submit')}}</el-button>
            </div>
        </el-col>
      </el-row>
    </div>
</template>
<script>
  import tablePagination from "@/components/tablePagination/TablePagination"
  import { membersCertApplies,certAppliesAudit } from '../../api/index'
  import { ElMessage } from 'element-plus'
  export default {
      components:{
        tablePagination
      },
      data() {
        return {
          tableData: [],
          page: {},
        }
      },
      mounted(){
        this.initPageData();
      },
      methods: {
        initPageData(data){
          let apiData = {}
          if(data){
            Object.assign(apiData,data)
          }
          membersCertApplies({apiData}).then( res => {
              this.tableData = res.data;
              this.page = res.meta.page;
          });
        },
        auditChanged(row){
          let apiData = {
            id: row.id,
            audit: (row.status === 2) ? true : false
          }
          certAppliesAudit({apiData}).then( res => {
            ElMessage.success({
                message: 'Success',
                type: 'success'
            });
          });
        },
        updatePage(data){
          this.initPageData(data);
        }
      }
    }
</script>